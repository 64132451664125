import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

const pageNotFound = () => (
    <div className="body">
        <Layout>
            <SEO title="Thank You" />
            <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
                <section className="section">
                    <div className="page-heading-parent">
                        <h1>Thank You!<em></em></h1>
                        <div className="line grey"></div>
                    </div>
                    <h2 className="heading-2">Your form submission has been received.</h2>
                    <div className="button-parent page">
                        <Button name="Back to our site" to="/" />
                    </div>
                </section>
            </div>
        </Layout>
    </div>
)
export default pageNotFound